import React, { useCallback, useContext, useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { AppBar, Box, Tab, Tabs } from "@mui/material";
import { endOfDay, isAfter } from "date-fns";
import { useParams } from "react-router-dom";
import { GET_REGISTRY } from "./api/queries";
import History from "./components/History";
import Main from "./components/Main";
import StatusStepper from "./components/StatusStepper";
import Items from "./components/Items";
import PickList from "./components/PickList";
import { UPDATE_REGISTRY, UPDATE_REGISTRY_STATUS } from "./api/mutations";
import OfflineOrders from "./components/OfflineOrders";
import { AppContext } from "../core/AppContext";

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const RegistryInfo = (_) => {
  const { showSnackBar, showProgressIndicator } = useContext(AppContext);
  const { registryId } = useParams();
  const [registry, setRegistry] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const client = useApolloClient();
  const [doUpdateRegistryStatus] = useMutation(UPDATE_REGISTRY_STATUS);
  const [doUpdateWishlist] = useMutation(UPDATE_REGISTRY);

  const doSave = async () => {
    const {
      _id,
      name,
      isPickup,
      isPublic,
      loyaltyCard,
      celebrantFirstName,
      celebrantLastName,
      summary,
      type,
      eventDate,
      eventAddress,
      eventContact,
      eventEmail,
      fb,
      twitter,
      instagram,
      image,
      subType,
      deliveryAddress,
      deliveryAddress2,
      lng,
      lat,
      deliveryDate,
      deliveryVehicle,
      discountClaimed,
    } = registry;

    const lngStr = lng ? (typeof lng === "string" ? lng : lng.toString()) : "0";
    const latStr = lat ? (typeof lat === "string" ? lat : lat.toString()) : "0";
    showProgressIndicator(true);

    const { loading, data } = await doUpdateWishlist({
      variables: {
        wishlist: {
          _id,
          name,
          isPickup,
          isPublic,
          loyaltyCard,
          celebrantFirstName,
          celebrantLastName,
          summary,
          type,
          eventDate,
          eventAddress,
          eventContact,
          eventEmail,
          fb,
          twitter,
          instagram,
          image,
          subType,
          deliveryAddress,
          deliveryAddress2,
          lng: lngStr,
          lat: latStr,
          deliveryDate,
          deliveryVehicle,
          discountClaimed,
        },
      },
    });

    if (!loading) {
      const { updateWishlist } = data;
      setRegistry(updateWishlist);
      checkEditable(updateWishlist.eventDate);
      showSnackBar({
        open: true,
        message: `${updateWishlist.name} has been updated!`,
        severity: "info",
      });
      showProgressIndicator(false);
    }
  };

  const saveAddress = async (props) => {
    const {
      _id,
      name,
      isPickup,
      isPublic,
      loyaltyCard,
      celebrantFirstName,
      celebrantLastName,
      summary,
      type,
      eventDate,
      eventAddress,
      eventContact,
      eventEmail,
      fb,
      twitter,
      instagram,
      image,
      subType,
      deliveryAddress,
      deliveryAddress2,
      lng,
      lat,
      deliveryDate,
      deliveryVehicle,
      discountClaimed,
    } = registry;
    showProgressIndicator(true);

    const { loading, data } = await doUpdateWishlist({
      variables: {
        wishlist: {
          _id,
          name,
          isPickup,
          isPublic,
          loyaltyCard,
          celebrantFirstName,
          celebrantLastName,
          summary,
          type,
          eventDate,
          eventAddress,
          eventContact,
          eventEmail,
          fb,
          twitter,
          instagram,
          image,
          subType,
          lng,
          lat,
          deliveryDate,
          deliveryVehicle,
          discountClaimed,
          ...props,
        },
      },
    });

    if (!loading) {
      const { updateWishlist } = data;
      setRegistry(updateWishlist);
      checkEditable(updateWishlist.eventDate);
      showSnackBar({
        open: true,
        message: `${updateWishlist.name} has been updated!`,
        severity: "info",
      });
      showProgressIndicator(false);
    }
  };

  const saveBranch = async (props) => {
    const {
      _id,
      name,
      isPickup,
      isPublic,
      loyaltyCard,
      celebrantFirstName,
      celebrantLastName,
      summary,
      type,
      eventDate,
      eventAddress,
      eventContact,
      eventEmail,
      fb,
      twitter,
      instagram,
      image,
      subType,
      deliveryAddress,
      deliveryAddress2,
      lng,
      lat,
      deliveryDate,
      deliveryVehicle,
      discountClaimed,
    } = registry;
    showProgressIndicator(true);

    const { loading, data } = await doUpdateWishlist({
      variables: {
        wishlist: {
          _id,
          name,
          isPickup,
          isPublic,
          loyaltyCard,
          celebrantFirstName,
          celebrantLastName,
          summary,
          type,
          eventDate,
          eventAddress,
          eventContact,
          eventEmail,
          fb,
          twitter,
          instagram,
          image,
          subType,
          lng,
          lat,
          deliveryDate,
          deliveryVehicle,
          discountClaimed,
          ...props,
        },
      },
    });

    if (!loading) {
      const { updateWishlist } = data;
      setRegistry(updateWishlist);
      checkEditable(updateWishlist.eventDate);
      showSnackBar({
        open: true,
        message: `${updateWishlist.name} has been updated!`,
        severity: "info",
      });
      showProgressIndicator(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  let tabIndex = 0;

  const fetchData = useCallback(async () => {
    if (!registry) {
      try {
        await setFetchedData();
      } catch (e) {}
    }
  });

  const checkEditable = (eventDate) => {
    const today = new Date();
    const isAfterEvent = isAfter(today, endOfDay(new Date(eventDate)));
    // console.log("isAfterEvent? ::", isAfterEvent);
    setDisabled(isAfterEvent);
  };

  const setFetchedData = async () => {
    showProgressIndicator(true);
    const { data, loading } = await client.query({
      query: GET_REGISTRY,
      variables: { id: registryId },
      fetchPolicy: "network-only",
    });
    if (!loading && data.getWishListbyId) {
      const { getWishListbyId } = data;
      setRegistry(getWishListbyId);
      checkEditable(getWishListbyId.eventDate);
      setDisabled(getWishListbyId.status !== "NEW");
      showProgressIndicator(false);
      return getWishListbyId;
    }
  };

  const packed = async () => {
    const { loading, data } = await doUpdateRegistryStatus({
      variables: {
        wishlistId: registry._id,
        status: "PACKED",
      },
    });

    if (!loading) {
      const { updateRegistryStatus } = data;
      setRegistry(updateRegistryStatus);
      showSnackBar({
        open: true,
        message: `${registry.name} is now Packed!`,
        severity: "info",
      });
    }
  };

  const pickComplete = async (isComplete) => {
    const { loading, data } = await doUpdateRegistryStatus({
      variables: {
        wishlistId: registry._id,
        status: isComplete ? "PICK_COMPLETE" : "PART_PICKED",
      },
    });

    if (!loading) {
      const { updateRegistryStatus } = data;
      setRegistry(updateRegistryStatus);
      if (updateRegistryStatus.status === "PICK_COMPLETE") {
        showSnackBar({
          open: true,
          message: `${registry.name} Picking is Completed!`,
          severity: "info",
        });
      }
    }
  };

  const resetRegData = async () => {
    const getWishListbyId = await setFetchedData();
    return getWishListbyId;
  };

  const updateRegistry = (evt) => {
    const { name, value } = evt.target;
    let updatedRegistry = { ...registry };

    if(name === "celebrantFirstName") {
      updatedRegistry["name"] = value;
      updatedRegistry[name] = value;
    } else {
      updatedRegistry[name] = value;
    }
    setRegistry(updatedRegistry);
  };

  const showError = (message) => {
    showSnackBar({
      open: true,
      message: message,
      severity: "error",
    });
  };

  const showSuccess = (message) => {
    showSnackBar({
      open: true,
      message: message,
      severity: "info",
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, registry]);

  return registry ? (
    <MainBox>
      <h2>
        [{registry.wishlistCode}] {registry.name}
      </h2>
      {/* <StatusStepper registry={registry} /> */}

      <AppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          centered
        >
          <Tab label="General" />
          <Tab label="Wishlist Items" />
          <Tab label="In Store Orders" />
          <Tab label="Online Orders" />
          <Tab label="History" />
        </Tabs>
      </AppBar>

      <TabPanel value={activeTab} index={tabIndex++}>
        <Main
          registry={registry}
          // setRegistry={setRegistry}
          saveAddress={saveAddress}
          showSuccess={showSuccess}
          showError={showError}
          // toggleDiscount={toggleDiscount}
          updateRegistry={updateRegistry}
          // updateCustomer={updateCustomer}
          doSave={doSave}
          disabled={disabled}
          saveBranch={saveBranch}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={tabIndex++}>
        <Items
          registry={registry}
          setRegistry={setRegistry}
          //updateRegistryStatus={doUpdateRegistryStatus}
          disabled={disabled}
          resetRegData={resetRegData}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={tabIndex++}>
        <OfflineOrders
          offlineOrders={registry.offlineOrders}
          enableCancel={registry.status === "PACKED"}
          resetRegData={resetRegData}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={tabIndex++}>
        <PickList
          id={registry._id}
          packed={packed}
          pickComplete={pickComplete}
          registry={registry}
          setRegistry={setRegistry}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={tabIndex++}>
        <History history={registry.history} />
      </TabPanel>
    </MainBox>
  ) : (
    <></>
  );
};

export default RegistryInfo;
