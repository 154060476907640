import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import numeral from "numeral";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import format from "date-fns/format";
import Fab from "@mui/material/Fab";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";
import { Button, CardHeader, Checkbox, FormControl, TextField } from "@mui/material";
import { DELETE_WISHLIST_ITEM } from "../api/mutations";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderDialog from "./OrderDialog";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import { AddPhotoAlternate } from "@mui/icons-material";
import UploadImage from "./UploadImage";
import { AppContext } from "../../core/AppContext";
import { useMutation } from "@apollo/client";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  image: {
    width: 60,
    height: "auto",
  },
  tableIndex: {
    fontWeight: "bold",
  },
  formControl: {
    width: "100%",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableIndex: {
    fontWeight: "bold",
  },
}));

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const Row = (props) => {
  const { item, doDelete, disabled, doSelect, openUploadDialog } = props;
  const [open, setOpen] = useState(false);

  console.log("disabled props::", disabled);

  const classes = useRowStyles();
  let maxQty = item.quantity;
  let reservedQuantity = 0;
  let boughtQuantity = 0;
  let filteredItems = { ...item };
  filteredItems.reservations = [];
  item.reservations.map((res) => {
    if (res.quantity > 0) {
      reservedQuantity += res.quantity;
      maxQty -= res.quantity;
      if (res.checkoutDate) boughtQuantity += res.quantity;
      filteredItems.reservations.push(res);
    }
  });
  let prodImage = item.primaryImage || item.imageUrl;
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {reservedQuantity > 0 && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <img
            className={classes.image}
            src={prodImage || "/no-image.jpg"}
          />
          {/* {(item.primaryImage == null || item.primaryImage == "") && (
            <IconButton onClick={() => openUploadDialog(item)}>
              <AddPhotoAlternate />
            </IconButton>
          )} */}
        </TableCell>
        <TableCell>
          {maxQty > 0 ? <Checkbox id={item._id} onChange={doSelect} disabled={disabled} /> : <Checkbox disabled />}
        </TableCell>
        <TableCell>
          {item.commercialName || item.productName} <br />
          {item.skuCode}
          <br />
          &#8369;{numeral(item.currentPrice).format("0,000.00")}
        </TableCell>
        <TableCell align="center">{numeral(item.quantity).format("0,000")}</TableCell>
        <TableCell align="center">{numeral(reservedQuantity).format("0,000")}</TableCell>
        <TableCell align="center">{numeral(boughtQuantity).format("0,000")}</TableCell>
        <TableCell align="center">
          {reservedQuantity === 0 && (
            <IconButton
              disabled={disabled}
              className={classes.margin}
              onClick={() => doDelete(item._id, item.commercialName || item.productName)}
              size="large"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#e8f8ff" }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Reservations
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableIndex}>
                      NAME
                    </TableCell>
                    <TableCell align="center" className={classes.tableIndex}>
                      QUANTITY
                    </TableCell>
                    <TableCell align="center" className={classes.tableIndex}>
                      DATE RESERVED
                    </TableCell>
                    <TableCell align="center" className={classes.tableIndex}>
                      DATE PURCHASED
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.reservations.map((reservation, i) => (
                    <TableRow key={i} style={reservation.isOfflineOrder ? { fontStyle: "italic" } : {}}>
                      <TableCell>
                        {reservation.firstName || ""} {reservation.lastName || ""}
                      </TableCell>
                      <TableCell align="center">{numeral(reservation.quantity).format("0,000")}</TableCell>
                      <TableCell align="center">{format(new Date(reservation.reserveDate), "MMM dd, yyyy")}</TableCell>
                      <TableCell align="center">
                        {reservation.checkoutDate ? format(new Date(reservation.checkoutDate), "MMM dd, yyyy") : " - "}
                      </TableCell>
                      <TableCell>{reservation.isOfflineOrder && <SignalWifiOffIcon />}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Items = (props) => {
  const { showSnackBar } = useContext(AppContext);
  const { registry, resetRegData, disabled } = props;
  const classes = useStyles();
  const [wishlistItems, setWishlistItems] = useState(registry.wishlistItems);
  const [createOrderDialog, setCreateOrderDialog] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [renderAt, setRenderAt] = useState(new Date());
  const [doDeleteWishlistItem] = useMutation(DELETE_WISHLIST_ITEM);
  const [doRecomputeDialog, setDoRecomputeDialog] = useState();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadItem, setUploadItem] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);

  const fetchAndResetRegData = async () => {
    const wishlist = await resetRegData();
    setWishlistItems(wishlist.wishlistItems);
    setRenderAt(new Date());
  };

  const filterWishlistItems = async (evt) => {
    const wishlist = await resetRegData();
    const { value } = evt.target;
    let filteredWishlistItems = wishlist.wishlistItems.filter(
      (e) =>
        (e.commercialName ?? e.productName ?? "").toLowerCase().includes(value.toLowerCase()) ||
        (e.skuCode ?? "").includes(value) ||
        (e.skuBarcode ?? "").includes(value)
    );
    setWishlistItems(filteredWishlistItems);
  };

  const doDelete = async (wishListItemId, name) => {
    const { data } = await doDeleteWishlistItem({
      variables: {
        wishlistId: registry._id,
        wishListItemId,
        owner: registry.owner,
      },
    });
    if (data) {
      console.log(data);
      let { deleteWishlistItem } = data;
      console.log(deleteWishlistItem);
      deleteWishlistItem = JSON.parse(deleteWishlistItem);
      if (deleteWishlistItem.success) {
        setWishlistItems(deleteWishlistItem.wishlistItems);
        showSnackBar({
          open: true,
          message: `${name} has been deleted from the registry`,
          severity: "success",
        });
      } else {
        showSnackBar({
          open: true,
          message: deleteWishlistItem.message,
          severity: "error",
        });
      }
    }
  };

  const displayMessage = (message) => {
    showSnackBar({
      open: true,
      message: message,
      severity: "info",
    });
  };

  const doSelect = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      console.log("add item");
      setDoRecomputeDialog({ id, checked });
      const wishlistIndex = wishlistItems.findIndex((el) => el._id === id);
      const wishlistItem = wishlistItems[wishlistIndex];

      let maxQty = wishlistItem.quantity;
      wishlistItem.reservations.map((res) => {
        if (res.quantity > 0) {
          maxQty -= res.quantity;
        }
      });

      const { _id, primaryImage, skuCode, commercialName, currentPrice, imageUrl, referencePrice } = wishlistItem;
      let discount = referencePrice === 0 ? parseFloat(currentPrice) * 0.10 : referencePrice > parseFloat(currentPrice) ? 0 : parseFloat(currentPrice) * 0.10
      orderItems.push({
        _id,
        primaryImage: primaryImage ?? imageUrl,
        skuCode,
        commercialName,
        maxQty,
        quantity: 1,
        currentPrice,
        amount: 1 * (currentPrice - discount),
        referencePrice,
        discount
      });
      setOrderItems(orderItems);
      computeTotalDiscounts(orderItems)
    } else {
      const wishlistIndex = orderItems.findIndex((el) => el._id === id);
      orderItems.splice(wishlistIndex, 1);
      setOrderItems(orderItems);
      setDoRecomputeDialog({ id, checked });
      computeTotalDiscounts(orderItems)
    }
  };

  const computeTotalDiscounts = async (orderItems) => {
    let discount = 0
    orderItems.map((v) => {
      discount += v?.discount
    })
    setTotalDiscount(discount)
  }

  const openUploadDialog = (item) => {
    setUploadDialogOpen(true);
    setUploadItem(item);
  };

  return (
    <div className={classes.root}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "20px",
          position: "-webkit-sticky",
          position: "sticky",
          top: "64px",
          backgroundColor: "#fff",
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <div>
            {orderItems.length} Item{orderItems.length == 1 ? "" : "s"} Selected
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setCreateOrderDialog(true)}
            disabled={disabled || orderItems.length < 1}
          >
            <ShoppingBasket style={{ marginRight: 10 }} /> Create In-Store Order
          </Button>
        </Box>
        <TextField
          label="Filter by product name, barcode or sku code"
          name="keyword"
          variant="outlined"
          onChange={(evt) => filterWishlistItems(evt)}
          fullWidth
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={5} />
              <TableCell align="center" className={classes.tableIndex} />
              <TableCell width={5} />
              <TableCell align="center" className={classes.tableIndex}>
                PRODUCT NAME
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}>
                WISHLIST
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}>
                RESERVED
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}>
                PAID
              </TableCell>
              <TableCell align="center" className={classes.tableIndex}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody key={renderAt}>
            {wishlistItems.map((item, i) => (
              <Row
                key={i}
                item={item}
                doDelete={doDelete}
                doSelect={doSelect}
                disabled={["PACKED", "SHIPPED", "CLOSE"].includes(registry.status) || disabled}
                openUploadDialog={openUploadDialog}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDialog
        key={renderAt}
        setCreateOrderDialog={setCreateOrderDialog}
        createOrderDialog={createOrderDialog}
        wishlistItems={wishlistItems}
        offlineItems={orderItems}
        wishlist={registry._id}
        subType={registry.subType}
        displayMessage={displayMessage}
        fetchAndResetRegData={fetchAndResetRegData}
        doRecomputeDialog={doRecomputeDialog}
        setOrderItems={setOrderItems}
        initialDiscount={totalDiscount}
      />
      <UploadImage
        uploadDialogOpen={uploadDialogOpen}
        setUploadDialogOpen={setUploadDialogOpen}
        uploadItem={uploadItem}
        fetchAndResetRegData={fetchAndResetRegData}
        wishlistItems={wishlistItems}
        setWishlistItems={setWishlistItems}
      />
    </div>
  );
};

export default Items;
