import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const ConfirmDialog = props => {
    const { message, title, setOpenConfirmDialog, openConfirmDialog, onCloseConfirmDialog } = props

    return <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} >
        <DialogTitle> {title} </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="accent" onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
            <Button color="secondary" onClick={onCloseConfirmDialog} autoFocus>Continue</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmDialog