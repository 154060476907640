import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'VisbyRoundCFMedium'",
    },
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#fdf4ba",
      contrastText: "#333",
    },
    secondary: {
      main: "#ff2f7f",
      contrastText: "#fff",
    },
    accent: {
      main: "#06a2b0  ",
      contrastText: "#fff",
    },
    error: {
      main: "rgb(255, 0, 0)",
      contrastText: "#333",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: "62.5%",
        },
      },
    },
  },
});

export default theme;
